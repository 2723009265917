<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="s3"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          :img-src="require('@/assets/images/backend/transcoding/interactvty.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalUrl')"
            >
              <b-form-input
                v-model="transcodingLocalUrl"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalUser')"
            >
              <b-form-input
                v-model="transcodingLocalUser"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalPassword')"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="transcodingLocalPassword"
                  :type="passwordFieldType"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                :label="$t('transcoding.availableFlavours')"
              >
                <b-form-checkbox-group
                  id="checkbox-group"
                  v-model="transcodingLocalVariants"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="flavour"
                />
              </b-form-group>
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalOutputBucketName')"
            >
              <b-form-input
                v-model="transcodingLocalOutputBucketName"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalBucketPathName')"
            >
              <b-form-input
                v-model="transcodingLocalBucketPathName"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalAwsAccessKeyId')"
            >
              <b-form-input
                v-model="transcodingLocalAwsAccessKeyId"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalAwsSecretAccessKey')"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="transcodingLocalAwsSecretAccessKey"
                  :type="passwordFieldType"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingLocalRegionName')"
            >
              <b-form-select
                v-model="transcodingLocalRegionName"
                class="mr-1"
                :options="transcodingRegionOptions"
                :placeholder="$t('transcoding.selectReg')"
                autocomplete="new-password"
              />
            </b-form-group>
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckboxGroup,
  BButton,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: ['272p', '360p', '720p', '1080p', '1440p', '2160p'],
      transcodingLocalVariants: [],
      transcodingLocalVariantsIDs: [],
      transcodingLocalUrl: '',
      transcodingLocalUser: '',
      transcodingLocalPassword: '',
      showLoading: false,
      transcodingLocalOutputBucketName: null,
      transcodingLocalBucketPathName: null,
      transcodingLocalAwsAccessKeyId: null,
      transcodingLocalAwsSecretAccessKey: null,
      transcodingLocalRegionName: null,
      transcodingRegionOptions: [
        { value: 'EU_WEST_1', text: this.$t('aws.zones.EU_WEST_1') },
        { value: 'US_EAST_1', text: this.$t('aws.zones.US_EAST_1') },
        { value: 'US_WEST_1', text: this.$t('aws.zones.US_WEST_1') },
        { value: 'US_WEST_2', text: this.$t('aws.zones.US_WEST_2') },
        { value: 'AP_SOUTH_1', text: this.$t('aws.zones.AP_SOUTH_1') },
        { value: 'AP_NORTHEAST_2', text: this.$t('aws.zones.AP_NORTHEAST_2') },
        { value: 'AP_SOUTHEAST_1', text: this.$t('aws.zones.AP_SOUTHEAST_1') },
        { value: 'AP_SOUTHEAST_2', text: this.$t('aws.zones.AP_SOUTHEAST_2') },
        { value: 'AP_NORTHEAST_1', text: this.$t('aws.zones.AP_NORTHEAST_1') },
        { value: 'CA_CENTRAL_1', text: this.$t('aws.zones.CA_CENTRAL_1') },
        { value: 'EU_CENTRAL_1', text: this.$t('aws.zones.EU_CENTRAL_1') },
        { value: 'EU_WEST_2', text: this.$t('aws.zones.EU_WEST_2') },
        { value: 'EU_WEST_3', text: this.$t('aws.zones.EU_WEST_3') },
        { value: 'EU_NORTH_1', text: this.$t('aws.zones.EU_NORTH_1') },
        { value: 'ME_SOUTH_1', text: this.$t('aws.zones.ME_SOUTH_1') },
        { value: 'SA_EAST_1', text: this.$t('aws.zones.SA_EAST_1') },
      ],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getInteractvtyPlatformData()
  },
  methods: {
    getInteractvtyPlatformData() {
      const userData = getUserData()
      const query = `
                query {
                client(id: "${userData.profile.client.id}"){
                    transcodingLocalUrl
                    transcodingLocalUser
                    transcodingLocalPassword
                    transcodingLocalOutputBucketName
                    transcodingLocalBucketPathName
                    transcodingLocalAwsAccessKeyId
                    transcodingLocalAwsSecretAccessKey
                    transcodingLocalRegionName
                    transcodingLocalVariants {
                    edges {
                        node {
                        name
                        id
                        }
                    }
                    }
                }
                }
            `
      axios.post('', { query }).then(res => {
        const variants = res.data.data.client.transcodingLocalVariants.edges
        if (variants.length > 0) {
          const names = variants.map(variant => variant.node.name)
          this.transcodingLocalVariants = names
        }
        this.transcodingLocalUrl = res.data.data.client.transcodingLocalUrl
        this.transcodingLocalUser = res.data.data.client.transcodingLocalUser
        this.transcodingLocalPassword = res.data.data.client.transcodingLocalPassword
        this.transcodingLocalOutputBucketName = res.data.data.client.transcodingLocalOutputBucketName
        this.transcodingLocalBucketPathName = res.data.data.client.transcodingLocalBucketPathName
        this.transcodingLocalAwsAccessKeyId = res.data.data.client.transcodingLocalAwsAccessKeyId
        this.transcodingLocalAwsSecretAccessKey = res.data.data.client.transcodingLocalAwsSecretAccessKey
        this.transcodingLocalRegionName = res.data.data.client.transcodingLocalRegionName
      })
    },

    async getVariants() {
      try {
        const result = await axios.post('', {
          query: `
            {
              allVariants {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          `,
        })

        const existingVariants = result.data.data.allVariants.edges.map(element => element.node)

        // Función auxiliar para manejar cada variante
        const handleVariant = async variant => {
          const variantName = variant.toLowerCase()
          const existingVariant = existingVariants.find(v => v.name.toLowerCase() === variantName)

          if (existingVariant) {
            this.transcodingLocalVariantsIDs.push(existingVariant.id)
          } else {
            try {
              const createVariantResult = await axios.post('', {
                query: `
                  mutation {
                    createVariant(
                      input: {
                        name: "${variant}"
                      }
                    ) {
                      variant {
                        id
                        name
                      }
                    }
                  }
                `,
              })

              this.transcodingLocalVariantsIDs.push(createVariantResult.data.data.createVariant.variant.id)
            } catch (err) {
              // Manejo de errores al crear la variante
            }
          }
        }

        // Iterar sobre transcodingLocalVariants usando forEach y ejecutar la función de ayuda
        await Promise.all(this.transcodingLocalVariants.map(variant => handleVariant(variant)))
      } catch (err) {
        // Manejo de errores al obtener las variantes existentes
      }
    },

    async onSubmit(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      await this.getVariants()
      const query = `
                mutation($id: ID!, $transcodingLocalUrl: String, $transcodingLocalUser: String, $transcodingLocalPassword: String, $transcodingLocalOutputBucketName: String, $transcodingLocalBucketPathName: String, $transcodingLocalAwsAccessKeyId: String, $transcodingLocalAwsSecretAccessKey: String, $transcodingLocalRegionName: UsersClientTranscodingRegionNameChoices){
                updateClient(id: $id, input: {
                    transcodingType: ITV
                    transcodingLocalUrl: $transcodingLocalUrl
                    transcodingLocalUser: $transcodingLocalUser
                    transcodingLocalPassword: $transcodingLocalPassword
                    transcodingLocalOutputBucketName: $transcodingLocalOutputBucketName
                    transcodingLocalBucketPathName: $transcodingLocalBucketPathName
                    transcodingLocalAwsAccessKeyId: $transcodingLocalAwsAccessKeyId
                    transcodingLocalAwsSecretAccessKey: $transcodingLocalAwsSecretAccessKey
                    transcodingLocalRegionName: $transcodingLocalRegionName
                    transcodingLocalVariants: [${this.transcodingLocalVariantsIDs}]
                }){
                    client {
                    id
                    transcodingType
                    transcodingLocalVariants {
                        edges {
                        node {
                            id
                        }
                        }
                    }
                    }
                }
                }
            `
      const variables = {
        id: userData.profile.client.id,
        transcodingLocalUrl: this.transcodingLocalUrl,
        transcodingLocalUser: this.transcodingLocalUser,
        transcodingLocalPassword: this.transcodingLocalPassword,
        transcodingLocalOutputBucketName: this.transcodingLocalOutputBucketName,
        transcodingLocalBucketPathName: this.transcodingLocalBucketPathName,
        transcodingLocalAwsAccessKeyId: this.transcodingLocalAwsAccessKeyId,
        transcodingLocalAwsSecretAccessKey: this.transcodingLocalAwsSecretAccessKey,
        transcodingLocalRegionName: this.transcodingLocalRegionName,
      }
      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(() => {
          showToast(this.$t('transcoding.transcodingChangeData'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('transcoding.transcodingErrorChangeData'), 2, this)
        })
      this.showLoading = false
      this.transcodingLocalVariantsIDs = []
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.nav-link .card {
  background-color: #f6f6f6 !important;
}

.nav-link.active .card {
  background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style>
