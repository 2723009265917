<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="s3"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          :img-src="require('@/assets/images/backend/transcoding/aws.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingInputBucketName')"
            >
              <b-form-input
                v-model="transcodingInputBucketName"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingAwsAccessKeyId')"
            >
              <b-form-input
                v-model="transcodingAwsAccessKeyId"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingAwsSecretAccessKey')"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="transcodingAwsSecretAccessKey"
                  :type="passwordFieldType"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('transcoding.transcodingRegionName')"
            >
              <b-form-select
                v-model="transcodingRegionName"
                class="mr-1"
                :options="transcodingRegionOptions"
                :placeholder="$t('transcoding.selectReg')"
                autocomplete="new-password"
              />
            </b-form-group>
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transcodingInputBucketName: null,
      transcodingAwsAccessKeyId: null,
      transcodingAwsSecretAccessKey: null,
      transcodingRegionName: null,
      transcodingRegionOptions: [
        { value: 'EU_WEST_1', text: this.$t('aws.zones.EU_WEST_1') },
        { value: 'US_EAST_1', text: this.$t('aws.zones.US_EAST_1') },
        { value: 'US_WEST_1', text: this.$t('aws.zones.US_WEST_1') },
        { value: 'US_WEST_2', text: this.$t('aws.zones.US_WEST_2') },
        { value: 'AP_SOUTH_1', text: this.$t('aws.zones.AP_SOUTH_1') },
        { value: 'AP_NORTHEAST_2', text: this.$t('aws.zones.AP_NORTHEAST_2') },
        { value: 'AP_SOUTHEAST_1', text: this.$t('aws.zones.AP_SOUTHEAST_1') },
        { value: 'AP_SOUTHEAST_2', text: this.$t('aws.zones.AP_SOUTHEAST_2') },
        { value: 'AP_NORTHEAST_1', text: this.$t('aws.zones.AP_NORTHEAST_1') },
        { value: 'CA_CENTRAL_1', text: this.$t('aws.zones.CA_CENTRAL_1') },
        { value: 'EU_CENTRAL_1', text: this.$t('aws.zones.EU_CENTRAL_1') },
        { value: 'EU_WEST_2', text: this.$t('aws.zones.EU_WEST_2') },
        { value: 'EU_WEST_3', text: this.$t('aws.zones.EU_WEST_3') },
        { value: 'EU_NORTH_1', text: this.$t('aws.zones.EU_NORTH_1') },
        { value: 'ME_SOUTH_1', text: this.$t('aws.zones.ME_SOUTH_1') },
        { value: 'SA_EAST_1', text: this.$t('aws.zones.SA_EAST_1') },
      ],
      showLoading: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getTranscodingPlatformData()
  },
  methods: {
    getTranscodingPlatformData() {
      const userData = getUserData()
      const query = `
        query {
        client(id: "${userData.profile.client.id}"){
            transcodingInputBucketName
            transcodingAwsAccessKeyId
            transcodingAwsSecretAccessKey
            transcodingRegionName
        }
        }
    `
      axios.post('', { query }).then(res => {
        this.transcodingInputBucketName = res.data.data.client.transcodingInputBucketName
        this.transcodingAwsAccessKeyId = res.data.data.client.transcodingAwsAccessKeyId
        this.transcodingAwsSecretAccessKey = res.data.data.client.transcodingAwsSecretAccessKey
        this.transcodingRegionName = res.data.data.client.transcodingRegionName
      })
    },
    onSubmit(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      // TODO: QUE PASA SI HAY TRANSCODIFICACIONES CONFIGURADOS EN OTRA PLATAFORMA O CON OTRAS CLAVES, YA NO TENDRIAMOS ACCESO A ESAS TRANSCODIFICACIONES. OJO
      const query = `
        mutation($id: ID!, $transcodingInputBucketName: String, $transcodingAwsAccessKeyId: String, $transcodingAwsSecretAccessKey: String, $transcodingRegionName: UsersClientTranscodingRegionNameChoices){
        updateClient(id: $id, input: {
            transcodingType: AWS,
            transcodingInputBucketName: $transcodingInputBucketName
            transcodingAwsAccessKeyId: $transcodingAwsAccessKeyId
            transcodingAwsSecretAccessKey: $transcodingAwsSecretAccessKey
            transcodingRegionName: $transcodingRegionName
        }){
            client {
            id
            transcodingType
            }
        }
        }
    `
      const variables = {
        id: userData.profile.client.id,
        transcodingInputBucketName: this.transcodingInputBucketName,
        transcodingAwsAccessKeyId: this.transcodingAwsAccessKeyId,
        transcodingAwsSecretAccessKey: this.transcodingAwsSecretAccessKey,
        transcodingRegionName: this.transcodingRegionName,
      }
      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(() => {
          showToast(
            this.$t('transcoding.transcodingChangeData'),
            1, this,
          )
        })
        .catch(() => {
          showToast(this.$t('transcoding.transcodingErrorChangeData'), 2, this)
        })
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.nav-link .card {
background-color: #f6f6f6 !important;
}

.nav-link.active .card {
background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style>
